import { BaseDto } from '@/shared/dtos/base-dto';

export class alimento_cantidad_ficha_platoDto extends BaseDto {
    public alimento_cantidades_id !: number;
    public cantidad !: number;
    public id_alimento !: number;
    public nueva_alimentos_id !: number;
    public nueva_alimentos_nombre !: string;
    public id_estructura_alimento  !: number;
    public id_estructura_plato  !: number;
    public mediciones_caseras !:string;
} 
