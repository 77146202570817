



















































































































































































































































































































































































































































































import { RouterNames } from "@/router/routernames";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import { Component, Vue } from "vue-property-decorator";
import dificultad_platoModule from "@/store/modules/dificultad_plato-module";
import { FilterDataDto } from "@/shared/dtos/visualizacion_dieta/FilterDataDto";
import { alimento_cantidad_ficha_platoDto } from "@/shared/dtos/visualizacion_dieta/alimento_cantidad_ficha_platoDto";
import { ssmMessageService } from "@/shared/services/message-service";
import { plato_ficha } from "@/shared/dtos/visualizacion_dieta/plato_ficha";

@Component({
  $_veeValidate: { validator: "new" },
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    EstructuraBasica: () =>
      import("@/views/base_alimentos/platos/calculoEstructuraBasica.vue"),
    AlimentosLista: () =>
      import("@/views/base_alimentos/alimentos/seleccionar_alimentos.vue"),
  },
})
export default class Platos_ficha extends Vue {
  public inicializar = false;
  public modeltab_cabecera = null;
  public modeltab_estadisticas = null;
  public modeltab_alimentos = null;
  public show_dialog = false;
  public reculcular = false;

  public async created() {
    dificultad_platoModule.getdificultad_platos();
    nueva_platoModule.getTipos_plato();
    if (this.is_new()) {
      await nueva_platoModule.getTallas();
      let arr = [];
      for (let i = 0; i < nueva_platoModule.tallas_plato.length; i++) {
        const element: any = nueva_platoModule.tallas_plato[i];
        arr.push(
          new plato_ficha({
            id: 0,
            id_talla_plato: element.id,
            talla_plato_id: element.id,
            talla: element.talla,
            alimentos: [],
          })
        );
      }
      this.$validator.reset();
      nueva_platoModule.onGetnueva_plato_ficha(arr);
      this.inicializar = true;
      this.reculcular = true;
    } else {
      await nueva_platoModule
        .getnueva_plato_ficha(Number.parseInt(this.$route.params.id))
        .then((x) => {
          this.inicializar = true;
        });
      try {
        this.reculcular = !this.datasource_cabezera!.cabezera_platos_sistema;
      } catch (error) {}
    }
  }

  public get plato_sistema() {
    try {
      return this.datasource_cabezera!.cabezera_platos_sistema;
    } catch (error) {}
    return false;
  }
  public get datasource_cabezera() {
    let x = nueva_platoModule.nueva_plato_ficha[0];
    if (x === undefined) {
      return;
    }
    return x;
  }

  public get datasource() {
    return nueva_platoModule.nueva_plato_ficha;
  }

  public get tallas() {
    return this.datasource.map((x) => {
      return { talla: x.talla, datos: x };
    });
  }

  public get dificultad_platos() {
    return dificultad_platoModule.dificultad_platos;
  }

  public get tipos_platos() {
    return nueva_platoModule.tipos_plato;
  }

  public recalcular_estructura() {
    var items = [
      "EstructuraBasica-S",
      "EstructuraBasica-M",
      "EstructuraBasica-L",
      "EstructuraBasica-XL",
    ];
    for (let i = 0; i < items.length; i++) {
      try {
        //@ts-ignore
        this.$refs["EstructuraBasica"][i].cargar_datos();
      } catch (error) {}
    }
  }

  public get alto() {
    return (
      " max-height: " +
      (this.$vuetify.breakpoint.height - 218) +
      "px ;overflow: auto;"
    );
  }

  public eliminar_ali_talla(
    item: any,
    ali: alimento_cantidad_ficha_platoDto,
    index: number
  ) {
    ssmMessageService.confirm(
      "¿Seguro que quiere eliminar el alimento de este plato?",
      "Eliminar alimento del plato",
      (cb) => {
        if (cb) {
          ssmMessageService.confirm(
            "¿Quiere eliminarlo de todas las tallas/tamaños ?",
            "Tallas/tamaños",
            (cb2) => {
              if (cb2) {
                for (let i = 0; i < this.datasource.length; i++) {
                  var indice = this.datasource[i].alimentos.findIndex(
                    (x) =>
                      x.id_estructura_alimento === ali.id_estructura_alimento
                  );
                  this.datasource[i].alimentos.splice(indice, 1);
                }
              } else {
                //@ts-ignore
                var indice = item.datos.alimentos.findIndex(
                  (x: alimento_cantidad_ficha_platoDto) =>
                    x.id_estructura_alimento === ali.id_estructura_alimento
                );
                item.datos.alimentos.splice(indice, 1);
              }
            }
          );
        }
      }
    );
  }

  public add_alimento(e: FilterDataDto) {
    this.reculcular = true;
    try {
      for (let i = 0; i < this.datasource.length; i++) {
        let alimento = new alimento_cantidad_ficha_platoDto();
        alimento.id = e.id;
        alimento.id_alimento = e.id;
        alimento.cantidad = 0;
        alimento.nueva_alimentos_id = e.id;
        alimento.nueva_alimentos_nombre = e.nombre;
        alimento.id_estructura_alimento = e.id_estructura;
        this.datasource[i].alimentos.push(alimento);
      }
    } catch (error) {}
    this.show_dialog = false;
    this.recalcular_estructura();
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }
  public cancel() {
    this.$router.push({ name: RouterNames.platoslista });
  }

  public eliminar() {
    //alimentoModule.eliminaralimento(new alimento(this.datasource));
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (this.is_new()) {
          nueva_platoModule
            .guardar_ficha_nueva_plato(this.datasource)
            .then((x) => this.$router.push({ name: RouterNames.platoslista }));
        } else {
          nueva_platoModule
            .modificar_ficha_nueva_plato(this.datasource)
            .then((x) => this.$router.push({ name: RouterNames.platoslista }));
        }
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }

  public clonar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        //@ts-ignore
        this.datasource_cabezera.nombre =
          //@ts-ignore
          this.datasource_cabezera.nombre + " - clonado";
        nueva_platoModule
          .guardar_ficha_nueva_plato(this.datasource)
          .then((x) => this.$router.push({ name: RouterNames.platoslista }));
      }
    });
    // suplementoModule.guardarinvetario(this.datasource);
  }
}
